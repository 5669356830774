const isExternalUrl = (url) => {
    if (typeof window === 'undefined') {
        return false;
    }

    const isExternal = url.indexOf('://' + window.location.hostname) === -1;
    const isNotInternal = url[0] !== '/';
    const hasProtocol = url.indexOf('://') > 1;

    return url && isExternal && isNotInternal && hasProtocol;
};

const objToQueryString = (obj) =>
    Object.keys(obj)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
        .join('&');

const searchToObj = (search) => {
    if (!search) {
        return {};
    }

    let obj = JSON.parse(
        '{"' +
            decodeURI(search)
                .replace(/\?/g, '')
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
    );

    return obj;
};

const searchToMapObj = (search) => {
    // Used for Components/MapPage.js
    if (!search) {
        return {
            activeCategories: [],
            activeRegions: [],
            view: 'grid',
        };
    }

    search = search.substring(1);
    let obj = JSON.parse(
        '{"' +
            decodeURI(search)
                .replace(/\?/g, '')
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
    );
    let newObj = {};

    if (obj.categories) {
        newObj['activeCategories'] = decodeURIComponent(obj.categories).split(
            ','
        );
    }
    if (obj.regions) {
        newObj['activeRegions'] = decodeURIComponent(obj.regions).split(',');
    }
    if (obj.view) {
        newObj['view'] = obj.view;
    }

    return newObj;
};

const searchToSearchObj = (search) => {
    // Used for Components/Search.js
    if (!search) {
        return {};
    }

    search = search.substring(1);
    let obj = JSON.parse(
        '{"' +
            decodeURI(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
    );
    let newObj = {};

    if (obj.query) {
        newObj['searchInput'] = obj.query;
    }
    if (obj.categories) {
        newObj['activeChips'] = decodeURIComponent(obj.categories).split(',');
    }
    if (obj.regions) {
        newObj['activeRegions'] = decodeURIComponent(obj.regions).split(',');
    }
    if (obj.view) {
        newObj['view'] = obj.view;
    }

    return newObj;
};

const getQueryArg = (key) => {
    if (typeof window === 'undefined') {
        return '';
    }

    const WINDOW = window ? (window.parent ? window.parent : window) : null;

    const urlParams = new URLSearchParams(WINDOW.location.search);
    return urlParams.get(key);
};

export {
    objToQueryString,
    searchToMapObj,
    searchToSearchObj,
    searchToObj,
    isExternalUrl,
    getQueryArg,
};
