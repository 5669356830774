import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { breakpoints } from '../../constants';
import useMediaQuery from '../../utils/useMediaQuery';
import styles from './Footer.module.scss';

const Footer = ({ footerMainMenu, footerPrivacyMenu, footerSocialMenu }) => {
    const isMobile = useMediaQuery({
        query: `(max-width: ${breakpoints.m}px)`,
    });

    return (
        <footer className={styles['Footer']}>
            <div className={styles['Footer__Container']}>
                <div className={styles['Footer__Row']}>
                    {isMobile ? (
                        <>
                            <SocialNav profiles={footerSocialMenu} />
                            <MainNav items={footerMainMenu} />
                        </>
                    ) : (
                        <>
                            <MainNav items={footerMainMenu} />
                            <SocialNav profiles={footerSocialMenu} />
                        </>
                    )}
                </div>
                <div className={styles['Footer__Row']}>
                    <PrivacyNav {...footerPrivacyMenu} />
                    <Logos />
                </div>
            </div>
        </footer>
    );
};

const MainNav = ({ items }) => {
    const { t } = useTranslation();

    return (
        <nav
            role="navigation"
            aria-labelledby="mainFooterNav"
            className={styles['Footer__MainNav']}>
            <h2 className="sr-only" id="mainFooterNav">
                {t('footer.mainNavHeader')}
            </h2>
            <ul className={styles['Footer__MainNav__Container']}>
                {items.map((item, i) => (
                    <li key={i} className={styles['Footer__MainNav__Item']}>
                        <a
                            className={styles['Footer__MainNav__Link']}
                            href={item.href}>
                            {item.title}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

const SocialNav = ({ profiles }) => {
    const { t } = useTranslation();

    return (
        <nav
            role="navigation"
            aria-labelledby="socialFooterNav"
            className={styles['Footer__SocialNav']}>
            <h2
                id="socialFooterNav"
                className={styles['Footer__SocialNav__Heading']}>
                {t('footer.followus')}
            </h2>
            {profiles.facebookUrl && (
                <a
                    href={profiles.facebookUrl}
                    target="_blank"
                    rel="noopener"
                    className={
                        styles['Footer__SocialNav__Link'] +
                        ' ' +
                        styles['Footer__SocialNav__Link--Fb']
                    }>
                    <span className="sr-only">{t('footer.facebook')}</span>
                </a>
            )}
            {profiles.instagramUrl && (
                <a
                    href={profiles.instagramUrl}
                    target="_blank"
                    rel="noopener"
                    className={
                        styles['Footer__SocialNav__Link'] +
                        ' ' +
                        styles['Footer__SocialNav__Link--Instagram']
                    }>
                    <span className="sr-only">{t('footer.instagram')}</span>
                </a>
            )}
            {profiles.youtubeUrl && (
                <a
                    href={profiles.youtubeUrl}
                    target="_blank"
                    rel="noopener"
                    className={
                        styles['Footer__SocialNav__Link'] +
                        ' ' +
                        styles['Footer__SocialNav__Link--Youtube']
                    }>
                    <span className="sr-only">{t('footer.youtube')}</span>
                </a>
            )}
        </nav>
    );
};

const Logos = () => (
    <div className={styles['Footer__Logos']}>
        <a
            className={styles['Footer__Logos__Link']}
            href="https://sweden.se/"
            target="_blank"
            rel="noopener">
            <div
                className={
                    styles['Footer__Logos__Logo'] +
                    ' ' +
                    styles['Footer__Logos__Logo--Sweden']
                }>
                <span className="sr-only">Sweden.se</span>
            </div>
        </a>
        <div
            className={
                styles['Footer__Logos__Link'] +
                ' ' +
                styles['Footer__Logos__Link']
            }>
            <div
                className={
                    styles['Footer__Logos__Logo'] +
                    ' ' +
                    styles['Footer__Logos__Logo--VisitSweden']
                }>
                <span className="sr-only">VisitSweden.com</span>
            </div>
        </div>
    </div>
);

const PrivacyNav = ({ items = [], copyrightYear }) => {
    const { t } = useTranslation();

    return (
        <div className={styles['Footer__PrivacyNav']}>
            {items.length > 0 && (
                <nav
                    role="navigation"
                    aria-labelledby="privacyFooterNav"
                    className={styles['Footer__PrivacyNav__Menu']}>
                    <h2 id="privacyFooterNav" className="sr-only">
                        {t('footer.privacyNavHeader')}
                    </h2>
                    <ul className={styles['Footer__PrivacyNav__Container']}>
                        {items.map((item, i) => (
                            <li
                                key={i}
                                className={styles['Footer__PrivacyNav__Item']}>
                                <a
                                    className={
                                        styles['Footer__PrivacyNav__Link']
                                    }
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener ">
                                    {item.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
            <div className={styles['Footer__PrivacyNav__CopyrightNotice']}>
                {t('footer.copyright', { copyrightYear })}
            </div>
        </div>
    );
};

PrivacyNav.propTypes = {
    items: PropTypes.array.isRequired,
    copyrightYear: PropTypes.number.isRequired,
};

MainNav.propTypes = {
    items: PropTypes.array.isRequired,
};

SocialNav.propTypes = {
    profiles: PropTypes.object.isRequired,
};

Footer.defaultProps = {
    footerMainMenu: [],
    footerPrivacyMenu: { items: [], copyrightYear: 2020 },
    footerSocialMenu: {},
};

Footer.propTypes = {
    footerMainMenu: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        })
    ),
    footerPrivacyMenu: PropTypes.object,
    footerSocialMenu: PropTypes.shape({
        facebookUrl: PropTypes.string,
        instagramUrl: PropTypes.string,
        youtubeUrl: PropTypes.string,
    }),
};

export default Footer;
