import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import styles from './NavigationSearch.module.scss';

const NavigationSearch = ({ searchPageUrl, onClose }) => {
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState('');
    const inputRef = createRef();
    useEffect(() => {
        inputRef.current.focus();
    });

    const handleChange = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    };

    return (
        <div className={styles['NavigationSearch']}>
            <form
                method="get"
                action={searchPageUrl}
                className={styles['NavigationSearch__Wrap']}
            >
                <input
                    ref={inputRef}
                    className={styles['NavigationSearch__Input']}
                    value={searchInput}
                    placeholder={t('search.placeholder')}
                    id="search"
                    name="q"
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyDown={handleChange}
                    type="search"
                />
                <span className="sr-only">{t('search.searchAriaLabel')}</span>
                <button
                    typeof="submit"
                    className={styles['NavigationSearch__Button']}
                    aria-label={t('search.searchAriaLabel')}
                />
                <button
                    className={styles['NavigationSearch__Cancel']}
                    aria-label={t('search.searchAriaClose')}
                    onClick={() => {
                        onClose();
                    }}
                />
            </form>
        </div>
    );
};

NavigationSearch.propTypes = {
    searchPageUrl: PropTypes.string,
    onClose: PropTypes.func,
};

NavigationSearch.defaultProps = {
    searchPageUrl: '',
    onClose: () => null,
};

export default NavigationSearch;
