/* global CookieScript */

import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import styles from './BasePage.module.scss';
// import useIsDesktop from '../../utils/useIsDesktop';
import Navigation from '../../components/Navigation';
import NavigationMobile from '../../components/NavigationMobile';
import InformationBar from '../../components/InformationBar';
import Footer from '../../components/Footer';
import NewsLetter from '../../components/NewsLetter';
import GlobalSiteSwitcher from '../../components/GlobalSiteSwitcher';
import dynamic from 'next/dynamic';
const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));
import { useCookieState, CookieStateContext } from './state';

const BasePage = ({
    children,
    navigation,
    siteSetting,
    wagtailUserbar,
    seo,
}) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isHydrated, setIsHydrated] = useState(false);

    useEffect(() => {
        const checkIfDesktop = () => {
            setIsDesktop(window.innerWidth >= 1280);
        };

        checkIfDesktop();

        window.addEventListener('resize', checkIfDesktop);

        setIsHydrated(true);

        return () => window.removeEventListener('resize', checkIfDesktop);
    }, []);



    const context = useCookieState();
    const {
        state,
        handleCookieAcceptAll,
        handleCookieAcceptCategories,
        handleCookieRejectAll,
    } = context;

    useEffect(() => {
        // Set initial cookies on load
        window.addEventListener('CookieScriptLoaded', function (e) {
            handleCookieAcceptCategories(
                CookieScript.instance.currentState().categories
            );
        });

        window.addEventListener('CookieScriptAccept', (e) => {
            const categories = e.detail?.categories;
            handleCookieAcceptCategories(categories);

            if (
                typeof gtag === 'function' &&
                categories.includes('targeting')
            ) {
                window.dataLayer.push({ event: 'consentGranted' });
            }
        });

        window.addEventListener('CookieScriptAcceptAll', () => {
            handleCookieAcceptAll();
            typeof gtag === 'function' &&
                window.dataLayer.push({ event: 'consentGranted' });
        });

        window.addEventListener('CookieScriptReject', () => {
            handleCookieRejectAll();
        });
    }, []);

    if (!isHydrated) {
        return null;
    }

    return (
        <>
            <Meta {...seo} />

            <CookieStateContext.Provider value={{ state }}>
                <div className={styles['BasePage']}>
                    {siteSetting && siteSetting.informationBarContent && (
                        <InformationBar
                            text={siteSetting.informationBarContent}
                        />
                    )}

                    {!!siteSetting?.siteSwitcher && (
                        <div className={styles['BasePage__SiteSwitcher']}>
                            <GlobalSiteSwitcher {...siteSetting.siteSwitcher} />
                        </div>
                    )}

                    {isDesktop && navigation ? (
                        <span className={styles['BasePage__Navigation']}>
                            <Navigation {...navigation} />
                        </span>
                    ) : navigation ? (
                        <span className={styles['BasePage__NavigationMobile']}>
                            <NavigationMobile {...navigation} />
                        </span>
                    ) : null}
                    {children}
                    {!!siteSetting && !!siteSetting.newsletterSignup && (
                        <NewsLetter
                            {...siteSetting.newsletterSignup}
                            modifier="Blue"
                        />
                    )}

                    {siteSetting && siteSetting.footerMainMenu && (
                        <Footer
                            footerMainMenu={siteSetting.footerMainMenu}
                            footerPrivacyMenu={siteSetting.footerPrivacyMenu}
                            footerSocialMenu={siteSetting.footerSocialMenu}
                        />
                    )}
                    {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
                </div>
            </CookieStateContext.Provider>
        </>
    );
};

const Meta = ({
    seoHtmlTitle = '',
    seoMetaDescription = '',
    seoOgTitle = '',
    seoOgDescription = '',
    seoOgUrl = '',
    seoOgImage = null,
    seoOgType = false,
    seoTwitterTitle = '',
    seoTwitterDescription = '',
    seoTwitterUrl = '',
    seoTwitterImage = null,
    seoMetaRobots = '',
    canonicalLink = '',
}) => (
    <Head>
        <title>{seoHtmlTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        {!!seoMetaDescription && (
            <meta name="description" content={seoMetaDescription} />
        )}
        {!!seoOgTitle && <meta property="og:title" content={seoOgTitle} />}
        {!!seoOgDescription && (
            <meta property="og:description" content={seoOgDescription} />
        )}
        {!!seoOgUrl && <meta property="og:url" content={seoOgUrl} />}
        {!!seoOgImage && <meta property="og:image" content={seoOgImage} />}
        {!!seoOgType && <meta property="og:type" content={seoOgType} />}
        {!!seoTwitterTitle && (
            <meta property="twitter:title" content={seoTwitterTitle} />
        )}
        {!!seoTwitterDescription && (
            <meta
                property="twitter:description"
                content={seoTwitterDescription}
            />
        )}
        {!!seoTwitterUrl && (
            <meta property="twitter:url" content={seoTwitterUrl} />
        )}
        {!!seoTwitterImage && (
            <meta property="twitter:image" content={seoTwitterImage} />
        )}
        {!!seoMetaRobots && <meta name="robots" content={seoMetaRobots} />}
        {!!canonicalLink && <link rel="canonical" href={canonicalLink} />}
    </Head>
);

BasePage.propTypes = {
    children: PropTypes.node,
    navigation: PropTypes.object,
    footer: PropTypes.object,
    cookies: PropTypes.object,
};

BasePage.defaultProps = {
    children: {},
    navigation: null,
    footer: {},
    cookies: {},
};

Meta.propTypes = {
    seoHtmlTitle: PropTypes.string,
    seoMetaDescription: PropTypes.string,
    seoOgTitle: PropTypes.string,
    seoOgDescription: PropTypes.string,
    seoOgImage: PropTypes.object,
    seoOgUrl: PropTypes.string,
    seoTwitterTitle: PropTypes.string,
    seoTwitterImage: PropTypes.object,
    seoMetaRobots: PropTypes.string,
    canonicalLink: PropTypes.string,
};

export default BasePage;
