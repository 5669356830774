import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import NavigationSearch from '../NavigationSearch';

import styles from './NavigationMobile.module.scss';

const NavigationMobile = ({
    menu,
    searchPageUrl,
    languages,
    handleDisable,
}) => {
    const { t } = useTranslation();

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const handleMenuToggle = useCallback(() => {
        handleDisable();
        setMenuIsOpen((menuIsOpen) => !menuIsOpen);
    }, [handleDisable]);

    const menuClasses = classNames(styles['NavigationMobile'], {
        [styles['NavigationMobile--Open']]: menuIsOpen,
    });

    useEffect(() => {
        const handleKeyPress = (e) => {
            const ESC = 27;
            const keyCode = e.keyCode;

            if (keyCode === ESC) {
                setMenuIsOpen(false);
            }
        };

        document.addEventListener('keyup', handleKeyPress);

        return () => {
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, []);

    return (
        <nav className={menuClasses}>
            <div className={styles['NavigationMobile__Container']}>
                {showSearch && (
                    <NavigationSearch
                        searchPageUrl={searchPageUrl}
                        onClose={() => setShowSearch(false)}
                    />
                )}
                {!showSearch && (
                    <>
                        <a
                            href="/"
                            className={styles['NavigationMobile__Logotype']}
                        >
                            <span className="sr-only">
                                Visit Sweden Logotype
                            </span>
                        </a>

                        <div className={styles['NavigationMobile__MenuWrap']}>
                            <div className={styles['NavigationMobile__Byline']}>
                                {t('navigation.byline')}
                            </div>
                            <div
                                className={styles['NavigationMobile__Actions']}
                            >
                                {!!searchPageUrl && (
                                    <button
                                        className={
                                            styles['NavigationMobile__Search']
                                        }
                                        onClick={() => {
                                            if (
                                                typeof window !== 'undefined' &&
                                                window.location.pathname !==
                                                    '/' &&
                                                searchPageUrl.indexOf(
                                                    window.location.pathname
                                                ) > -1
                                            ) {
                                                document
                                                    .querySelector(
                                                        '.SearchFilter__HeadingLabel'
                                                    )
                                                    .scrollIntoView({
                                                        behavior: 'smooth',
                                                    });
                                            } else {
                                                setShowSearch(true);
                                            }
                                        }}
                                    >
                                        <span className="sr-only">
                                            {t('navigation.search')}
                                        </span>
                                    </button>
                                )}

                                <button
                                    className={
                                        styles['NavigationMobile__Toggle']
                                    }
                                    onClick={handleMenuToggle}
                                >
                                    {menuIsOpen ? (
                                        <span className="sr-only">
                                            {t('navigation.close')}
                                        </span>
                                    ) : (
                                        <span className="sr-only">
                                            {t('navigation.open')}
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={styles['NavigationMobile__ItemsContainer']}>
                <div className={styles['NavigationMobile__Items']}>
                    {menu.map((item, index) => (
                        <SubNavigation key={index} {...item} />
                    ))}

                    {languages && languages.length > 1 && (
                        <MobileLanguageSwitcher languages={languages} />
                    )}
                </div>
            </div>
        </nav>
    );
};

const MobileLanguageSwitcher = ({ languages }) => {
    const { t } = useTranslation();

    const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
    const handleSubMenuToggle = useCallback((e) => {
        e.preventDefault();
        setSubMenuIsOpen((subMenuIsOpen) => !subMenuIsOpen);
    }, []);
    const activeLanguage = languages.filter((l) => l.isActive)[0] || {};

    const buttonToggleClassesLanguage = classNames(
        styles['ButtonToggle'],
        styles['ButtonToggle--Grey300'],
        { [styles['ButtonToggle--Language']]: !subMenuIsOpen },
        { [styles['ButtonToggle--LanguageClose']]: subMenuIsOpen }
    );

    return (
        <>
            <div className={styles['NavigationMobile__ItemContainer']}>
                <div
                    className={
                        styles['NavigationMobile__Item'] +
                        ' ' +
                        styles['NavigationMobile__Item--TopLevel']
                    }
                >
                    <span className={styles['NavigationMobile__Language']}>
                        {t('navigation.language')}
                    </span>
                    <span
                        className={styles['NavigationMobile__ActiveLanguage']}
                    >
                        {activeLanguage.title}
                    </span>
                    <button
                        className={buttonToggleClassesLanguage}
                        onClick={handleSubMenuToggle}
                        aria-expanded={subMenuIsOpen}
                    />
                </div>
            </div>
            {subMenuIsOpen &&
                languages &&
                languages.map((language, index) => (
                    <div
                        key={index}
                        className={
                            styles['NavigationMobile__ItemContainer'] +
                            ' ' +
                            styles['NavigationMobile__ItemContainer--SubLevel']
                        }
                    >
                        <div
                            className={
                                styles['NavigationMobile__Item'] +
                                ' ' +
                                styles['NavigationMobile__Item--SubLevel'] +
                                ' ' +
                                styles['NavigationMobile__Item--Language']
                            }
                        >
                            <span
                                className={classNames(
                                    styles['NavigationMobile__Checkbox'],
                                    {
                                        [styles[
                                            'NavigationMobile__Checkbox--Active'
                                        ]]: language.isActive,
                                    }
                                )}
                            />
                            {t(`languages.${language.langCode}`)}
                        </div>
                        <a
                            href={language.href}
                            className={styles['NavigationMobile__ItemLink']}
                        >
                            <span className="sr-only">
                                {t(`languages.${language.langCode}`)}
                            </span>
                        </a>
                    </div>
                ))}
        </>
    );
};

const SubNavigation = ({ title, items, href }) => {
    const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
    const handleSubMenuToggle = useCallback((e) => {
        e.preventDefault();
        setSubMenuIsOpen((subMenuIsOpen) => !subMenuIsOpen);
    }, []);
    const buttonToggleClasses = classNames(
        styles['ButtonToggle'],
        styles['ButtonToggle--Grey300'],
        { [styles['ButtonToggle--Plus']]: !subMenuIsOpen },
        { [styles['ButtonToggle--Minus']]: subMenuIsOpen }
    );

    const topNavigationClasses = classNames(
        styles['NavigationMobile__Item'],
        styles['NavigationMobile__Item--TopLevel']
    );

    return (
        <>
            <div className={styles['NavigationMobile__ItemContainer']}>
                <a className={topNavigationClasses} href={href}>
                    {title}
                </a>
                {items && !!items.length && (
                    <button
                        className={buttonToggleClasses}
                        onClick={handleSubMenuToggle}
                        aria-expanded={subMenuIsOpen}
                    />
                )}
            </div>
            {subMenuIsOpen &&
                items &&
                items.map((item, index) => (
                    <SubNavigationItem key={index} {...item} />
                ))}
        </>
    );
};

const SubNavigationItem = ({ title, href }) => {
    const { t } = useTranslation();

    const subNavigationClasses = classNames(
        styles['NavigationMobile__ItemContainer'],
        styles['NavigationMobile__ItemContainer--SubLevel']
    );

    const subNavigationLinkClasses = classNames(
        styles['NavigationMobile__Item'],
        styles['NavigationMobile__Item--SubLevel']
    );

    return (
        <div className={subNavigationClasses}>
            <span className={subNavigationLinkClasses}>{title}</span>
            <a href={href} className={styles['NavigationMobile__ItemLink']}>
                <span className="sr-only">
                    {t('navigationMobile.readMore', { title })}
                </span>
            </a>
        </div>
    );
};

NavigationMobile.propTypes = {
    menu: PropTypes.array,
    searchPageUrl: PropTypes.string,
    languages: PropTypes.array,
    handleDisable: PropTypes.func,
};

NavigationMobile.defaultProps = {
    menu: [],
    languages: [],
    searchPageUrl: '',
    handleDisable: () => false,
};

MobileLanguageSwitcher.propTypes = {
    languages: PropTypes.array,
};

SubNavigation.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    items: PropTypes.array,
};

SubNavigationItem.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
};

export default NavigationMobile;
