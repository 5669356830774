import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isExternalUrl } from '../../utils/url';

import styles from './Button.module.scss';

const Button = ({
    link,
    label,
    text,
    onClick,
    icon,
    type,
    size,
    ariaExpanded,
    ariaControls,
    style = {},
    disabled,
    attrs = {},
    modifiers = [],
}) => {
    const Tag = link ? 'a' : 'button';

    let props = {};
    let iconClasses;
    const isExternal = link && isExternalUrl(link);

    if (link && !disabled) {
        props.href = link;
    }

    if (onClick && !disabled) {
        props.onClick = onClick;
    }

    if (label) {
        props['aria-label'] = label;
    }

    if (ariaControls) {
        props['aria-controls'] = ariaControls;
    }

    props['aria-expanded'] = ariaExpanded;

    const classes = classNames(
        styles['Button'],
        {
            [styles['Button--Link']]: link,
            [styles[`Button--${type}`]]: type,
            [styles[`Button--${size}`]]: size,
            [styles['Button--Icon']]: icon,
        },
        modifiers.map((modifier) => styles[`Button--${modifier}`])
    );

    iconClasses = classNames(styles['Button__Icon']);

    return (
        <Tag
            {...props}
            className={classes}
            style={style}
            disabled={disabled}
            target={isExternal ? '_blank' : null}
            {...attrs}
        >
            {text}
            {icon && <img src={icon} alt="" className={iconClasses} />}
        </Tag>
    );
};

Button.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
};

Button.defaultProps = {
    link: '',
    label: '',
    text: '',
    onClick: () => {},
    icon: '',
    type: '',
};

export default Button;
