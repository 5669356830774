import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { setCookie, getCookie } from '../../utils/cookies';
import styles from './InformationBar.module.scss';

const InformationBar = ({ text, identifier }) => {
    const { t } = useTranslation();

    const [hidden, setHidden] = useState(true);

    const dismissPopup = () => {
        setHidden(true);
        setCookie(identifier, true);
    };

    useEffect(() => {
        if (!getCookie(identifier)) {
            setHidden(false);
        }
    }, [identifier]);

    if (hidden || !text) return null;

    return (
        <div
            className={styles['InformationBar']}
            visible={hidden ? 'hidden' : 'visible'}
        >
            <div className={styles['InformationBar__Container']}>
                <div className={styles['InformationBar__TextWrapper']}>
                    {text && (
                        <p
                            className={styles['InformationBar__Text']}
                            dangerouslySetInnerHTML={{ __html: text }}
                        ></p>
                    )}
                </div>
                <button
                    onClick={() => dismissPopup()}
                    className={styles['InformationBar__CloseButton']}
                >
                    <span className="sr-only">{t('informationBar.close')}</span>
                </button>
            </div>
        </div>
    );
};

InformationBar.propTypes = {
    text: PropTypes.string,
    identifier: PropTypes.string,
};

InformationBar.defaultProps = {
    text: '',
    identifier: styles['InformationBar'],
};

export default InformationBar;
